<template>
  <b-card
    :header="t.t('users.header')"
    header-bg-variant="primary"
    header-text-variant="white"
  >
    <!-- filters and search -->
    <FiltersAndSearch
      :id.sync="id"
      :searchKey.sync="searchKey"
      :activeOnly.sync="activeOnly"
      :priceSort.sync="priceSort"
      :sortOrder.sync="sortOrder"
    />
    <!-- end of filters and search -->
    <!-- export -->
    <ExportFromTo :exportHandler="exportCustomersHandler" class="mb-2" />
    <!-- end export -->
    <div v-if="false" class="custom-search">
      <b-form-group>
        <div class="d-flex align-items-center">
          <b-form-input
            v-model="searchQuery"
            placeholder="Search"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>
    <b-table
      ref="refCustomersListTable"
      sticky-header
      bordered
      hover
      responsive
      class="shadow-sm rounded"
      thead-tr-class="order-main-thead"
      :items="customersList"
      :busy="isTableBusy"
      :fields="fields"
      primary-key="id"
      show-empty
      empty-text="No matching records found"
      small
      @row-clicked="onRowClicked"
    >
      <template #cell(actions)="data">
        <div v-if="areActionsAllowed" class="d-flex">
          <b-button
            variant="flat-danger"
            class="btn-icon"
            @click="blockCustomerButton(data.item)"
          >
            <feather-icon size="16" icon="LockIcon" />
          </b-button>
          <b-button
            class="btn-icon"
            variant="flat-danger"
            @click="removeCustomerButton(data.item)"
          >
            <feather-icon size="16" icon="TrashIcon" />
          </b-button>
          <b-button
            class="btn-icon"
            variant="flat-success"
            @click="onRowClicked(data.item)"
          >
            <feather-icon size="16" icon="EyeIcon" />
          </b-button>
        </div>
      </template>
    </b-table>
    <Pagination
      :fetch-data="getCustomersList"
      :skip.sync="skip"
      :total-rows="totalRows"
      :take.sync="take"
    />
  </b-card>
</template>

<script>
import { BTable, BCard, BFormInput, BFormGroup, BButton } from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
import Pagination from "@/views/components/Pagination/Pagination.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Swal from "sweetalert2";
import isAllowed from "@core/utils/isAllowed";
import ExportFromTo from "../components/ExportFromTo/ExportFromTo.vue";
import FiltersAndSearch from "../components/FiltersAndSearch/FiltersAndSearch.vue";

const FileSaver = require("file-saver");

export default {
  components: {
    BTable,
    BCard,
    BButton,
    BFormInput,
    BFormGroup,
    Pagination,
    ExportFromTo,
    FiltersAndSearch,
  },
  data() {
    return {
      customersList: [],
      t: null,
      totalRows: 0,
      take: 20,
      skip: 0,
      from: null,
      to: null,
      sortOrder: 2,
      id: "",
      searchKey: "",
      activeOnly: true,
      priceSort: false,
    };
  },
  computed: {
    ...mapState("customersModule", ["customers", "isTableBusy"]),
    areActionsAllowed() {
      // import isAllowed from "@core/utils/isAllowed";
      const roles = JSON.parse(localStorage.getItem("userRoles"));
      return isAllowed(["Admin", "Editor", "Support"], roles);
    },
    fields() {
      return [
        {
          key: this.areActionsAllowed ? "actions" : "",
          label: this.t.t("global.action"),
        },
        {
          key: "displayId",
          label: this.t.t("users.id"),
        },
        {
          key: "firstName",
          label: this.t.t("users.fullName"),
          formatter: (value, key, item) =>
            item.isRegistered
              ? `${item.firstName} ${item.lastName}`
              : "კონტაქტი",
        },
        {
          key: "mobile",
          label: this.t.t("users.mobile"),
        },
        {
          key: "email",
          label: this.t.t("users.email"),
        },
        {
          key: "isDeleted",
          label: this.t.t("users.isDeleted"),
          formatter: (value) =>
            value
              ? this.t.t("users.registered")
              : this.t.t("users.notRegistered"),
        },
        {
          key: "isRegistered",
          label: this.t.t("users.isRegistered"),
          formatter: (value) =>
            value
              ? this.t.t("users.registered")
              : this.t.t("users.notRegistered"),
        },
        {
          key: "receivedGiftQuantity",
          label: this.t.t("users.receivedGiftQuantity"),
        },
        {
          key: "sentGiftQuantity",
          label: this.t.t("users.sentGiftQuantity"),
        },
        {
          key: "revenueAmount",
          label: this.t.t("users.revenueAmount"),
          formatter: (value) => `${this.t.t("users.currency")} ${value}`,
        },
      ];
    },
  },
  watch: {
    searchKey() {
      this.getCustomersList();
    },
    activeOnly() {
      this.getCustomersList();
    },
    id() {
      this.getCustomersList();
    },
    take() {
      this.getCustomersList();
    },
    priceSort() {
      this.getCustomersList();
    },
    sortOrder() {
      this.getCustomersList();
    },
    customers(newVal) {
      this.customersList = newVal.customers;
    },
  },
  created() {
    this.getCustomersList();
    this.t = this.$i18n;
  },
  methods: {
    ...mapActions("customersModule", [
      "getCustomers",
      "getCustomerById",
      "deleteCustomer",
      "blockCustomer",
      "exportCustomers",
    ]),
    toast(title = "Success", variant = "succes") {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: "BellIcon",
          variant,
        },
      });
    },
    swalConfirmation(type = "delete", item) {
      return Swal.fire({
        title: `Are you sure you want to ${type} customer - ${item}?`,
        text: `You will ${type} this customer!`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#28a745",
        cancelButtonColor: "#d33",
        confirmButtonText: `Yes, ${type}!`,
      });
    },
    async getCustomersList() {
      try {
        await this.getCustomers({
          skip: this.skip,
          take: this.take,
          searchKey: this.searchKey,
          activeOnly: this.activeOnly,
          sortOrder: this.sortOrder,
          id: this.id,
          priceSort: this.priceSort,
        });
        this.totalRows = this.customers.count;
      } catch (err) {
        console.log(err);
      }
    },
    async blockCustomerButton(item) {
      const result = await this.swalConfirmation("block", `${item.firstName} ${item.lastName}`);
      if (result.isConfirmed) {
        try {
          await this.blockCustomer(item.id);
          this.toast("Customer blocked Successfully", "success");
          this.getCustomersList();
        } catch (error) {
          console.log(error);
        }
      }
    },
    async removeCustomerButton(item) {
      const result = await this.swalConfirmation("delete", `${item.firstName} ${item.lastName}`);
      if (result.isConfirmed) {
        try {
          await this.deleteCustomer(item.id);
          this.toast("Customer Deleted Successfully", "success");
          this.getCustomersList();
        } catch (error) {
          console.log(error);
        }
      }
    },
    async onRowClicked(item) {
      this.$router.push({ name: "singleCustomer", params: { id: item.id } });
    },
    async exportCustomersHandler(from, to) {
      const payload = { from, to };

      try {
        const response = await this.exportCustomers(payload);
        const date = new Date().toLocaleDateString();
        FileSaver.saveAs(response.data, `Customers-${date}.xlsx`);
        this.toast("Exported successfully", "success");
      } catch (error) {
        const responsObj = await error.response.data.text();
        this.toast(JSON.parse(responsObj).error.message, "danger");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-search {
  display: flex;
  justify-content: flex-end;
  @media (max-width: 600px) {
    width: 100%;
    justify-content: flex-start;
  }
}
.form-group {
  @media (max-width: 600px) {
    width: 100%;
  }
}
.pointer {
  cursor: pointer;
}
</style>
